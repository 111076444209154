import { css } from "@emotion/css";
import { FC } from "react";
import { divStyle } from "../seeDetailsButton";
import { getOverallColor } from "../../../utils/municipality";

type Props = {
  label: string;
  onClick: () => void;
  isEnable?: boolean;
};

export const SettingSubButton: FC<Props> = ({
  label,
  onClick,
  isEnable = true,
}) => {
  return (
    <div
      className={isEnable ? buttonStyle : disableButtonStyle}
      onClick={() => isEnable && onClick()}
    >
      <span className={isEnable ? labelStyle : disablelabelStyle}>{label}</span>
    </div>
  );
};

const buttonStyle = css`
  ${divStyle};
  border: solid 2px ${getOverallColor("mainColor")};
  cursor: pointer;
`;

const labelStyle = css`
  font-size: 16px;
  font-weight: 600;
  color: ${getOverallColor("mainColor")};
`;

const disableButtonStyle = css`
  ${divStyle};
  border: solid 2px #eaeaea;
`;

const disablelabelStyle = css`
  ${labelStyle};
  color: #333333 ;
  opacity: 45%;
`;
