import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type GreenActionState = {
  hasDoneActionAfterStartup: boolean; // アプリ起動後にアクション実行されているか
  actionCount: number | undefined;
  bingoCount: number | undefined;
  currentBingoCard: number;
  isVisitedActionTop: boolean;
};

const initialState: GreenActionState = {
  hasDoneActionAfterStartup: false,
  actionCount: undefined,
  bingoCount: undefined,
  currentBingoCard: 1,
  isVisitedActionTop: false,
};

const greenActionSlice = createSlice({
  name: "greenAction",
  initialState,
  reducers: {
    setDoneActionAfterStartup: (state, action: PayloadAction<{}>) => ({
      ...state,
      hasDoneActionAfterStartup: true,
    }),
    updateBingoInfo: (
      state,
      action: PayloadAction<{
        bingoCount: number;
        actionCount?: number;
      }>
    ) => ({
      ...state,
      actionCount:
        action.payload.actionCount !== undefined
          ? action.payload.actionCount
          : state.actionCount,
      bingoCount: action.payload.bingoCount,
    }),
    setCurrentBingoCard: (state, action: PayloadAction<number>) => ({
      ...state,
      isVisitedActionTop: true,
      currentBingoCard: action.payload,
    }),
    // setVisitedActionTop: (state, action: PayloadAction<void>) => ({
    //   ...state,
    //   isVisitedActionTop: true,
    // }),
  },
});

export default greenActionSlice.reducer;

export const {
  setDoneActionAfterStartup,
  updateBingoInfo,
  setCurrentBingoCard,
  // setVisitedActionTop,
} = greenActionSlice.actions;
