export const path = {
  root: "/",
  actionTop: "/greenAction",
  actionDetail: (actionId: string) =>
    `/greenAction/detail?actionId=${actionId}`,
  accountRegist: "/account/regist",
  accountDelete: "/account/delete",
  tutorial: "/tutorial",
  contactUs: "/contactUs",
  // addHome: "/addHome",
  logout: "/logout",
  logoutConfirm: "/logoutConfirm",
  terms: "/contents/terms.html",
  faq: "https://support.tokyobingo.zeroca.jp/portal/",
  privacyPolicy: "/contents/terms.html#privacyPolicy",
  error: "/error",
  ready: "/ready/index.html",
  associationConfirm: "/associationConfirm/start",
  smartMeter: "/smartMeterCooperation",
};

const DOMAIN_COMMON = process.env.NEXT_PUBLIC_DURIAN_URL;
const WINDOW_NAME_COMMON = "durian";
let openedWindow: Window | null;
/**
 * 共通アプリを別タブで開く
 * @param path
 */
export const openCommonApp = (path: string): void => {
  if (typeof window !== "undefined") {
    if (openedWindow) {
      openedWindow.close();
    }
    openedWindow = window.open(`${DOMAIN_COMMON}${path}`, WINDOW_NAME_COMMON);
  }
};
