import { createContext } from "react";

const displayThreshold = {
  tabPortraitMin: 744,
  tabLandscape: 1024,
};

export const mediaQuery = {
  portrait: `@media (min-width: ${displayThreshold.tabPortraitMin}px)`,
  landscape: `@media (min-width: ${displayThreshold.tabLandscape}px)`,
};

// 表示中のデバイス判定
export type Display = "SP" | "TAB_PORTRAIT" | "TAB_LANDSCAPE";

export const checkDisplay = (): Display => {
  if (typeof window !== "undefined" && window.localStorage) {
    const width = window.innerWidth;
    // console.log(width);
    if (width < displayThreshold.tabPortraitMin) {
      return "SP";
    } else if (width < displayThreshold.tabLandscape) {
      return "TAB_PORTRAIT";
    }
  }
  return "TAB_LANDSCAPE";
};

// Reactコンポーネントから表示中のデバイスを取得するためのContext
export const DisplayContext = createContext<Display>("TAB_PORTRAIT");
