import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SourceService } from "../../utils/error";

export type ErrorState = {
  sourceService?: SourceService;
  type?: "Error" | "Warning";
  errorCode?: string;
  auth0UserId?: string;
  message?: string;
  userMessage?: string;
  inputParams?: string[];
};

const initialState: ErrorState = {
  sourceService: undefined,
  type: "Error", // デフォルト値（使用していない）
  errorCode: undefined,
  message: undefined,
  userMessage: undefined,
  inputParams: undefined,
  auth0UserId: undefined,
};

const errorSlice = createSlice({
  name: "error",
  initialState,
  reducers: {
    setError: (state, action: PayloadAction<ErrorState>) => ({
      ...state,
      sourceService: action.payload.sourceService,
      errorCode: action.payload.errorCode,
      message: action.payload.message,
      userMessage: action.payload.userMessage,
      inputParams: action.payload.inputParams,
      auth0UserId: action.payload.auth0UserId,
    }),
    clearError: (state, action: PayloadAction<void>) => initialState,
  },
});

export default errorSlice.reducer;

export const { setError, clearError } = errorSlice.actions;
