import { configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { api as graphql } from "./graphql";
import greenActionApi from "../services/cms/greenAction";
import auth0BackendApi from "../services/auth0/";
import GreenActionReducer from "./greenAction/slice";
import Auth0ActionReducer from "./auth0/slice";
import ErrorReducer from "./error/slice";
import auth0UserInfoApi from "./auth0/userInfo";

const store = configureStore({
  reducer: {
    greenAction: GreenActionReducer,
    auth0Action: Auth0ActionReducer,
    error: ErrorReducer,
    [graphql.reducerPath]: graphql.reducer,
    [greenActionApi.reducerPath]: greenActionApi.reducer,
    [auth0BackendApi.reducerPath]: auth0BackendApi.reducer,
    [auth0UserInfoApi.reducerPath]: auth0UserInfoApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(graphql.middleware)
      .concat(greenActionApi.middleware)
      .concat(auth0BackendApi.middleware)
      .concat(auth0UserInfoApi.middleware),
  devTools: process.env.NODE_ENV !== "production",
  // preloadedState: preloadedState(),
});
export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
