import { css } from "@emotion/css";
import { FC } from "react";
import { mediaQuery } from "../../../utils/ui";
import { titleStyle } from "../DoneButton";
import { getOverallColor } from "../../../utils/municipality";

interface Props {
  title: string;
  onclick: Function;
  actionStatus: string;
}

export const SeeDetailsButton: FC<Props> = ({
  title = "",
  onclick,
  actionStatus,
}) => {
  return (
    <div
      className={actionStatus === "enable" ? doneStyle : seeDetailStyle}
      onClick={() => onclick()}
    >
      <span
        className={
          actionStatus === "enable" ? doneTitleStyle : seeDetailTitleStyle
        }
      >
        {title}
      </span>
    </div>
  );
};

const doneTitleStyle = css`
  ${titleStyle};
  color: #fff;
  font-size: 16px;
`;

const seeDetailTitleStyle = css`
  ${titleStyle};
  color: ${getOverallColor("subColor")};
  font-size: 16px;
`;

export const buttonWidth = 311;
export const divStyle = css`
  width: 311px;
  height: 44px;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 8px;
  ${mediaQuery.portrait} {
    width: 311px;
    height: 44px;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 8px;
  }
  ${mediaQuery.landscape} {
    width: ${buttonWidth}px;
    height: 44px;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 8px;
  }
`;

const doneStyle = css`
  cursor: pointer;
  ${divStyle};
  background-color: ${getOverallColor("mainColor")};
  box-shadow: 0 13px 16px 0 ${getOverallColor("shadowColor")};
`;

const seeDetailStyle = css`
  cursor: pointer;
  ${divStyle};
  border: solid 2px ${getOverallColor("mainColor")};
`;
