// Or from '@reduxjs/toolkit/query/react'
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "../store";
import { UserInfoResponse } from "./types";

export const api = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: "https://" + process.env["NEXT_PUBLIC_AUTH0_DOMAIN"],
    prepareHeaders: (headers, { getState }) => {
      headers.set("Content-Type", "application/json");
      const token = (getState() as RootState).auth0Action.accessToken; // TODO このアクセストークンでよい？
      if (token) headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  reducerPath: "auth0UserInfo",
  endpoints: (builder) => ({
    userInfo: builder.query<UserInfoResponse, void>({
        query: () => `/userinfo`,
      }),
  }),
});
export default api;
export const { useUserInfoQuery } = api;
