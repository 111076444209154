import { css } from "@emotion/css";
import { FC, useCallback, useState } from "react";
import { getOverallColor } from "../../../utils/municipality";

type Props = {
  title: string;
  onclick: Function;
};

// 一度だけ押下出来るボタン
export const DoneButton: FC<Props> = ({ title = "", onclick }) => {
  const [isDisabled, setisDisabled] = useState(false);

  const doneClick = useCallback(() => {
    if (!isDisabled) {
      onclick();
      setisDisabled(true);
    } else {
    }
  }, [isDisabled, setisDisabled, onclick]);

  return (
    <div className={buttonStyle} onClick={() => doneClick()}>
      <span className={titleStyle}>{title}</span>
    </div>
  );
};

export const titleStyle = css`
  width: 63px;
  height: 28px;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  color: #fff;
`;

export const buttonStyle = css`
  width: 311px;
  height: 44px;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 8px;
  background-color: ${getOverallColor("mainColor")};
  box-shadow: 0 13px 16px 0 ${getOverallColor("shadowColor")};
  cursor: pointer;
`;
