/* eslint-disable @next/next/no-img-element */
import { css } from "@emotion/css";
import { useRouter } from "next/router";
import { FC, useCallback } from "react";
import { clearError, ErrorState } from "../../../services/error/slice";
import { useAppDispatch } from "../../../services/store";
import { sourceServices } from "../../../utils/error";
import { mediaQuery } from "../../../utils/ui";
import { SettingSubButton } from "../../atoms/SettingSubButton";
import { headerHeight } from "../../organisms/Header";

type Props = ErrorState;

const prefix = "GK";
const envCode = "A";

export const Error: FC<Props> = ({
  sourceService = sourceServices.none,
  errorCode = "001",
  userMessage = "処理中にエラーが発生しました。\nしばらくしてから再度アクセスしてください。",
  type = "Error",
}) => {
  const code = `${prefix}-${envCode}${sourceService.code}-${
    type === "Error" ? "E" : "W"
  }${errorCode}`;

  return (
    <main className={backgroundStyle}>
      <article className={boxStyle}>
        <div className={imgBoxStyle}>
          <img
            src={`/error/errorHeader.png?${process.env.NEXT_PUBLIC_IMAGE_PARAMETERS}`}
            height={160}
            width={160}
            alt="エラーが発生しました"
            className={imgStyle}
          />
        </div>
        <h1 className={titleStyle}>エラーが発生しました</h1>
        <p className={messageStyle}>{userMessage}</p>
        <p className={codeStyle}>{`エラーコード：${code}`}</p>
        <div className={buttonAreaStyle}>
          <SettingSubButton
            label="ホームに戻る"
            onClick={() => {
              window.location.href = "/";
            }}
          />
        </div>
      </article>
    </main>
  );
};

const backgroundStyle = css`
  width: 100vw;
  height: 100vh;
  background-image: url(${`/error/announcebackgroundSp.png?${process.env.NEXT_PUBLIC_IMAGE_PARAMETERS}`});
  background-repeat: no-repeat;
  background-size: cover;
  ${mediaQuery.portrait} {
    background-image: url(${`/error/announcebackground.png?${process.env.NEXT_PUBLIC_IMAGE_PARAMETERS}`});
    background-repeat: no-repeat;
    background-size: contain;
  }
  ${mediaQuery.landscape} {
    width: 100vw;
    height: 100vh;
    background-image: url(${`/error/announcebackground.png?${process.env.NEXT_PUBLIC_IMAGE_PARAMETERS}`});
    background-repeat: no-repeat;
    background-size: cover;
  }
`;
const boxStyle = css`
  width: 325px;
  height: 100%;
  padding-top: 68px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  ${mediaQuery.portrait} {
    width: 400px;
    height: 100%;
    padding-top: 128px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
  }
  ${mediaQuery.landscape} {
    width: 560px;
    height: 100%;
    padding-top: 164px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
  }
`;

const imgStyle = css`
  width: 160px;
  height: 160px;
`;

const imgBoxStyle = css`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
`;

const titleStyle = css`
  font-size: 28px;
  font-weight: 600;
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
  ${mediaQuery.landscape} {
    font-size: 40px;
    font-weight: 600;
    width: 100%;
    text-align: center;
    margin-bottom: 27px;
  }
`;

const messageStyle = css`
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  white-space: pre-wrap;
  margin-bottom: 18px;
  ${mediaQuery.landscape} {
    font-size: 22px;
    font-weight: 600;
    text-align: center;
    white-space: pre-wrap;
    margin-bottom: 20px;
  }
`;

const codeStyle = css`
  font-size: 14px;
  font-weight: 300;
  text-align: center;
  ${mediaQuery.landscape} {
    font-size: 16px;
    font-weight: 300;
    text-align: center;
  }
`;

const buttonAreaStyle = css`
  position: absolute;
  bottom: 0;
  left: calc((100% - 311px) / 2);
  margin-bottom: 64px;
  ${mediaQuery.landscape} {
    position: absolute;
    bottom: 0;
    left: calc((560px - 311px) / 2);
    margin-bottom: 64px;
  }
`;
