// Or from '@reduxjs/toolkit/query/react'
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "../store";
import { PatchUsersRequest } from "./types";

export const api = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: process.env["NEXT_PUBLIC_AUTH0_BACKEND_API_BASE_URL"] as string,
    prepareHeaders: (headers, { getState }) => {
      headers.set("Content-Type", "application/json");
      const token = (getState() as RootState).auth0Action.accessToken; // TODO このアクセストークンでよい？
      if (token) headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  reducerPath: "auth0Backend",
  endpoints: (builder) => ({
    patchUsers: builder.mutation<void, PatchUsersRequest>({
      query: (patchUsersRequest) => ({
        url: "users",
        method: "PATCH",
        body: patchUsersRequest,
      }),
    }),
    deleteUsers: builder.mutation<void, void>({
      query: () => ({
        url: "users",
        method: "DELETE",
      })
    })
  }),
});
export default api;
export const { usePatchUsersMutation, useDeleteUsersMutation } = api;
