import { createApi } from "@reduxjs/toolkit/query/react";
import { graphqlRequestBaseQuery } from "@rtk-query/graphql-request-base-query";
import { GraphQLClient } from "graphql-request";

export const client = new GraphQLClient(
  process.env["NEXT_PUBLIC_HASURA_BASE_URL"] as string
);

export const GRAPHQL_TAG_TYPE = {
  USER_ACTIONS: "UserActions",
  VIEW_NOW: "ViewNow",
  USERS: "Users",
  ELECTRICITY_USAGE_PER_USER_DATE: "ElectricityUsagePerUserDate",
  ELECTRICITY_USAGE_DETAIL: "ElectricityUsageDetail",
  AREA_TEMPERATURE_BY_DATE: "GetAreaTemperatureByAreaAndDate",
  MY_DATA: "GetMyData",
  LATEST_ELECTRICITY_DATA: "GetUserLatestData",
  LATEST_ELECTRICITY_DATA_DATE: "GetUserElectricUsageDate",
  ELECTRICITY_USAGE_SPECIFIC_DATE: "ElectricityUsageSpecificDate",
  ELECTRICITY_USAGE_SPECIFIC_DATE_PATTERN_5:
    "ElectricityUsageSpecificDatePattern5",
  LAST_USER_ELECTRICITY_DATA: "GetLastUserElectricityData",
  CONSENT_APPLICATION_INFO_FOR_BINGO: "GetConsentApplicationInfoForBingo",
} as const;

export const api = createApi({
  baseQuery: graphqlRequestBaseQuery({ client }),
  reducerPath: "graphql",
  endpoints: () => ({}),
  tagTypes: [
    GRAPHQL_TAG_TYPE.USER_ACTIONS,
    GRAPHQL_TAG_TYPE.VIEW_NOW,
    GRAPHQL_TAG_TYPE.USERS,
    GRAPHQL_TAG_TYPE.ELECTRICITY_USAGE_PER_USER_DATE,
    GRAPHQL_TAG_TYPE.ELECTRICITY_USAGE_DETAIL,
    GRAPHQL_TAG_TYPE.AREA_TEMPERATURE_BY_DATE,
    GRAPHQL_TAG_TYPE.MY_DATA,
    GRAPHQL_TAG_TYPE.LATEST_ELECTRICITY_DATA,
    GRAPHQL_TAG_TYPE.LATEST_ELECTRICITY_DATA_DATE,
    GRAPHQL_TAG_TYPE.ELECTRICITY_USAGE_SPECIFIC_DATE,
    GRAPHQL_TAG_TYPE.ELECTRICITY_USAGE_SPECIFIC_DATE_PATTERN_5,
    GRAPHQL_TAG_TYPE.LAST_USER_ELECTRICITY_DATA,
    GRAPHQL_TAG_TYPE.CONSENT_APPLICATION_INFO_FOR_BINGO,
  ],
});
