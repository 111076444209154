import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { ErrorState } from "../../services/error/slice";

export type SourceService = {
  name: string;
  code: string;
};

export const sourceServices = {
  none: {
    name: "None",
    code: "00",
  } as SourceService,
  auth0React: {
    name: "Auth0React",
    code: "01",
  } as SourceService,
  auth0Api: {
    name: "Auth0Api",
    code: "02",
  } as SourceService,
  cms: {
    name: "CMS",
    code: "03",
  } as SourceService,
  graphql: {
    name: "hasura",
    code: "04",
  } as SourceService,
};

export type ApiErrorInfo = {
  error: FetchBaseQueryError | SerializedError;
  params?: string[];
  userMessage?: string;
};

type CheckErrorProps = {
  auth0Error?: Error;
  graphqlErrors?: Array<ApiErrorInfo | undefined>;
  cmsErrors?: Array<ApiErrorInfo | undefined>;
  auth0UserId?: string;
};
/**
 * 各種APIエラー情報から、エラー出力対象となるものを抽出して返却する
 * @param props
 * @returns
 */
export const checkError = (props: CheckErrorProps): ErrorState | undefined => {
  if (!props.auth0Error && !props.graphqlErrors && !props.cmsErrors) {
    return;
  }
  // 複数のエラーが発生している場合は、最初のものを出力する
  const graphqlError = props.graphqlErrors
    ? props.graphqlErrors.find((error) => !!error)
    : undefined;
  const cmsError = props.cmsErrors
    ? props.cmsErrors.find((error) => !!error)
    : undefined;
  if (props.auth0Error) {
    // console.log(props.auth0Error)
    return {
      sourceService: sourceServices.auth0React,
      errorCode: "001",
      message: props.auth0Error.message,
      auth0UserId: props.auth0UserId,
    };
  } else if (graphqlError) {
    // console.log(graphqlError)
    let errorCode = "001";
    let message: string | undefined = undefined;
    if (
      typeof (graphqlError.error as FetchBaseQueryError).status === "number"
    ) {
      const queryError = graphqlError.error as FetchBaseQueryError;
      errorCode = `${queryError.status}`;
      message =
        typeof queryError.data === "string" ? queryError.data : undefined;
    }
    if (!message && typeof (graphqlError.error as Error).message === "string") {
      message = (graphqlError.error as Error).message;
    }
    return {
      sourceService: sourceServices.graphql,
      errorCode,
      message,
      inputParams: graphqlError.params,
      userMessage: graphqlError.userMessage,
      auth0UserId: props.auth0UserId,
    };
  } else if (cmsError) {
    // console.log(cmsError)
    let errorCode = "001";
    let message: string | undefined = undefined;
    if (typeof (cmsError.error as FetchBaseQueryError).status === "number") {
      const queryError = cmsError.error as FetchBaseQueryError;
      errorCode = `${queryError.status}`;
      message =
        typeof queryError.data === "string" ? queryError.data : undefined;
    }
    if (!message && typeof (cmsError.error as Error).message === "string") {
      message = (cmsError.error as Error).message;
    }
    return {
      sourceService: sourceServices.cms,
      errorCode,
      message,
      inputParams: cmsError.params,
      userMessage: cmsError.userMessage,
      auth0UserId: props.auth0UserId,
    };
  }
  return;
};
/**
 * エラー発生時にログ出力するメッセージを取得する
 * @param error 
 * @returns 
 */
export const correctErrorMessage = (error: unknown): string | undefined => {
  if (!error) return undefined;
  if ((error as Error).message) return (error as Error).message;
  return JSON.stringify(error);
};
