export interface MunicipalitiesItem {
  groupName: string;
  groupOptions: string[];
}

interface Config {
  municipality: string;
  tutorialPages: Array<TutorialPage>;
  label: Record<LabelKey, string>;
  img: Record<ImgKey, string>;
  url: Record<string, string>;
  flag: Record<FlagKey, boolean>;
  bingoPointThresholds: Array<number>;
  overallColor: Record<string, string>;
  overallHeight: Record<string, string>;
  municipalities: MunicipalitiesItem[];
}
// https://zenn.dev/okz/articles/08f4ecfd45d2c7
let config: Config = require("./config.json");

const MUNICIPALITY_BASE = "municipality";
const createImgSrc = (path: string) =>
  `/${MUNICIPALITY_BASE}/${config.municipality}${path}?${process.env.NEXT_PUBLIC_IMAGE_PARAMETERS}`;
const createHtmlSrc = (path: string) =>
  `/${MUNICIPALITY_BASE}/${config.municipality}${path}`;

/**
 * 自治体名を取得する
 * @returns
 */
export const getMunicipalityName = (): string => config.municipality;

/**
 * 自治体ごとの出し分け文言を取得する
 * @returns
 */
export const getMunicipalities = (): MunicipalitiesItem[] =>
  config.municipalities;

/**
 * 自治体ごとの出し分け文言を取得する
 * @param key
 * @returns
 */
export const getLabel = (key: LabelKey): string => config.label[key];

/**
 * 自治体ごとの出し分け画像のパスを取得する
 * @param key
 * @returns
 */
export const getImgSrc = (key: ImgKey): string => {
  return createImgSrc(config.img[key]);
};
/**
 * 自治体ごとの出し分け背景画像のパスを取得する
 * @param key
 * @returns
 */
export const getBackgroundImgSrc = (key: ImgKey): string => {
  return config.img[key] + `?${process.env.NEXT_PUBLIC_IMAGE_PARAMETERS}`;
};

/**
 * 自治体ごとの出し分け機能切替のフラグを取得する
 * @param key
 * @returns
 */
export const getFlag = (key: FlagKey): boolean => config.flag[key];

/**
 * 自治体ごとのチュートリアル情報を取得する
 * @returns
 */
export const getTutorialPages = () => config.tutorialPages;

/**
 * 自治体ごとの出し分けURLを取得する
 * @param key
 * @returns
 */
export const getUrl = (key: UrlKey): string => config.url[key];

/**
 * 自治体ごとの出し分けHTMLのパスを取得する
 * @param key
 * @returns
 */
export const getHtmlSrc = (key: UrlKey): string => {
  return createHtmlSrc(config.url[key]);
};

export const getBingoPointThresholds = (): Array<number> =>
  config.bingoPointThresholds;

/**
 * 自治体ごとの出し分けする色を取得する
 * @param key
 * @returns
 */
export const getOverallColor = (key: OverallColorKey): string =>
  config.overallColor[key];

export const getOverallHeight = (key: OverallHeight): string =>
  config.overallHeight[key];

type TutorialPage = {
  imgSrc: string;
  message: string;
  messageSp: string;
  altText: string;
};

/**
 * 自治体ごとの出し分け設定ファイルlabelのkey名
 */
type LabelKey =
  | "Top-NameTitle"
  | "Top-ServiceText"
  | "BingoTop-Title"
  | "Top-AddToHomeScreen"
  | "UserRegist-TermsText"
  | "UserRegist-TermsLinkText"
  | "UserRegist-TermsLinkText2"
  | "UserRegist-FormLabel"
  | "UserRegist-SchoolName"
  | "UserRegist-AnnotationLabel"
  | "UserRegist-TopText"
  | "UserRegist-Confirm"
  | "UserRegist-TitleComplete"
  | "UserRegist-TitleOuter"
  | "UserRegist-ChangeButton"
  | "Action-AnswerButton"
  | "BingoTop-BaseText1"
  | "BingoTop-BaseText2"
  | "BingoTop-Period"
  | "BingoTop-PeriodLASTDAY"
  | "Other-HamburgerMenu-PrivacyPolicyText"
  | "Other-ServiceName"
  | "SmartMeter-Text";

/**
 * 自治体ごとの出し分け設定ファイルimgのkey名
 */
export type ImgKey =
  | "Top-ServiceLogo"
  | "Top-Pin"
  | "Top-ActionIcon"
  | "Top-Visual00"
  | "Top-Visual01"
  | "Top-Visual02"
  | "Top-Visual03"
  | "Top-Visual04"
  | "Top-Visual05"
  | "Top-Visual06"
  | "Top-Visual07"
  | "Top-Visual08"
  | "Top-Visual09"
  | "Top-Visual10"
  | "Top-Visual11"
  | "Top-Visual12"
  | "Top-Visual13"
  | "Top-Visual14"
  | "Top-Visual15"
  | "Top-Visual16"
  | "Top-Visual17"
  | "Top-Visual18"
  | "Top-Visual19"
  | "Top-Visual20"
  | "Top-Visual21"
  | "Top-Visual22"
  | "Top-Visual23"
  | "Top-Visual24"
  | "BingoTop-Title"
  | "BingoTop-BackgroundImage"
  | "BingoTop-BackgroundImage-Tb"
  | "BingoTop-BingoAnimation1"
  | "BingoTop-BingoAnimation2"
  | "BingoTop-BingoAnimation3"
  | "BingoTop-BingoAnimation4"
  | "BingoTop-BingoCardPrev"
  | "BingoTop-BingoCardNext"
  | "BingoTop-OnPage"
  | "BingoTop-OffPage"
  | "BingoTop-ActionIcon"
  | "Header-Menu"
  | "Header-HeaderPower"
  | "Header-Home"
  | "Header-Action"
  | "HeaderMenu-MoreActionNext"
  | "BingoTopModal-Close"
  | "BingoTopModal-ActionBack"
  | "ActionDetail-ActionBack"
  | "ActionDetail-MoveToTop"
  | "ActionDetail-Close"
  | "ActionDetail-AllowDown"
  | "ActionDetail-LeftAllow"
  | "ActionDetail-SpeechBalloon"
  | "ActionDetail-GraphQuestion"
  | "ActionDetail-rightDoubleAllow"
  | "ActionDetail-Outer-link"
  | "ActionDetail-SelectedMarker"
  | "ActionDetail-GuideImg1"
  | "ActionDetail-GuideImg2"
  | "BingoModal-ActionBingoTitle"
  | "ActionComlete-Close"
  | "ActionComlete-ActionComleteTextImg"
  | "ActionComlete-QuizSuccess"
  | "ActionComlete-ActionDetailTips"
  | "ActionComlete-QuestionIcon"
  | "Tutorial-OnPage"
  | "Tutorial-OffPage"
  | "Tutorial-MoreActionNext"
  | "BingoTop-BingoCardNext"
  | "BingoTop-BingoCardNext"
  | "Top-Background"
  | "Top-Background-Tb"
  | "UserRegist-ServiceLogo"
  | "SmartMeter-VisualImg"
  | "SmartMeter-SmartMeterIcon"
  | "SmartMeter-InnerLink"
  | "Other-HomeIconImg"
  | "Other-FaviconImg"
  | "Top-AppointmentImg"
  | "TermsAgreementMain"
  | "HintModal-Pattern1"
  | "HintModal-Pattern2"
  | "HintModal-Pattern3"
  | "HintModal-Pattern4"
  | "HintModal-Pattern5";
/**
 * 自治体ごとの出し分け設定ファイルimgのkey名
 */
export type UrlKey =
  | "Present-Url00"
  | "Present-Url01"
  | "Present-Url02"
  | "Present-Url03"
  | "Present-Url04"
  | "Present-Url05"
  | "Present-Url06"
  | "Present-Url07"
  | "Present-Url08"
  | "Present-Url09"
  | "Present-Url10"
  | "Present-Url11"
  | "Present-Url12"
  | "Present-Url13"
  | "Present-Url14"
  | "Present-Url15"
  | "Present-Url16"
  | "Present-Url17"
  | "Present-Url18"
  | "Present-Url19"
  | "Present-Url20"
  | "Present-Url21"
  | "Present-Url22"
  | "Present-Url23"
  | "Present-Url24"
  | "Top-ZerocaLink"
  | "Other-HamburgerMenu-FaqLink"
  | "Other-HamburgerMenu-TermsLink"
  | "Other-HamburgerMenu-PrivacyPolicyLink";
/**
 * 自治体ごとの出し分け設定ファイルflagのkey名
 */
type FlagKey = "Top-AppointmentEnable" | "Top-GetPresentEnable";

/**
 * 自治体ごとの出し分け設定ファイルoverallColorのkey名
 */
type OverallColorKey = "mainColor" | "subColor" | "shadowColor" | "linearColor";

/**
 * 自治体ごとの出し分け設定ファイルoverallHeightのkey名
 */
type OverallHeight = "nameHeight" | "subColor";
