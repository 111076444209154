import React from "react";
import { RootState, AppDispatch } from "../../services/store";
import { clearError, ErrorState } from "../../services/error/slice";
import { connect } from "react-redux";
import { Error } from "../../components/templates/Error";
import * as Sentry from '@sentry/nextjs';

type Props = {
  children: JSX.Element;
  errorState: ErrorState;
  // clearError: () => void;
};

class ErrorBoundary extends React.Component<Props, { hasError: boolean }> {
  constructor(props: Props | Readonly<Props>) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
    Sentry.captureException(error);
  }

  render() {
    // console.log(this.props);
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <Error {...this.props.errorState} />;
    }

    return this.props.children;
  }

  // componentDidUpdate(prevProps: Props) {
  //   console.log(this.props.errorState);
  //   console.log(prevProps.errorState.sourceService);
  //   if (
  //     this.props.errorState.sourceService !==
  //       prevProps.errorState.sourceService &&
  //     !this.props.errorState.sourceService
  //   ) {
  //     this.setState({hasError: false});
  //   }
  // }
}

const mapStateToProps = (state: RootState) => ({
  errorState: state.error,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  clearError: () => dispatch(clearError()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ErrorBoundary);

// export default ErrorBoundary;
